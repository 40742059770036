@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.footer-container-desk {
  font-family: "Raleway", serif;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5;
  color: #353a42;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column; }
  .footer-container-desk .footer-container-form-bg {
    padding: 2rem 0px 1.25rem;
    position: relative;
    background-color: #0d98e6;
    overflow: hidden; }
    .footer-container-desk .footer-container-form-bg-circle-1 {
      position: absolute;
      width: 467px;
      height: 477px;
      left: -101px;
      top: -110.77px;
      background: rgba(219, 255, 0, 0.2);
      -webkit-filter: blur(52px);
      filter: blur(52px); }
    .footer-container-desk .footer-container-form-bg-circle-2 {
      position: absolute;
      width: 330px;
      height: 330px;
      left: 181px;
      top: -32.77px;
      background: rgba(48, 230, 175, 0.2);
      -webkit-filter: blur(90px);
      filter: blur(90px); }
    .footer-container-desk .footer-container-form-bg-circle-3 {
      position: absolute;
      width: 309px;
      height: 309px;
      left: 157px;
      top: 252.23px;
      background: rgba(161, 48, 230, 0.4);
      -webkit-filter: blur(52px);
      filter: blur(52px); }
    .footer-container-desk .footer-container-form-bg-circle-4 {
      position: absolute;
      width: 330px;
      height: 330px;
      left: -90px;
      top: 461px;
      background: rgba(48, 230, 175, 0.3);
      -webkit-filter: blur(90px);
      filter: blur(90px); }
  .footer-container-desk .footer-container-form-container {
    padding: 40px;
    background: rgba(255, 255, 255, 0.85);
    border-radius: 10px;
    -webkit-box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6.7px);
    -webkit-backdrop-filter: blur(6.7px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-inline: 250px;
    margin-block: 80px;
    gap: 50px; }
    @media (min-width: 1600px) {
      .footer-container-desk .footer-container-form-container {
        margin-inline: 350px;
        padding: 40px 80px; } }
  .footer-container-desk .footer-container-text-form {
    width: 50%; }
  .footer-container-desk .footer-form-container {
    width: 50%; }
  .footer-container-desk .tag {
    font-family: "Raleway", serif;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5;
    color: #353a42;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.5rem 0;
    color: #8a8a8a;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 5px;
    margin: 0; }
  .footer-container-desk .block-title {
    font-family: "Raleway", serif;
    font-style: normal;
    font-weight: 500;
    color: #353a42;
    font-size: 2.5rem;
    line-height: 1.1;
    margin-block: 0.5rem 0.5rem; }
    @media (min-width: 1800px) {
      .footer-container-desk .block-title {
        font-size: 3rem; } }
  .footer-container-desk .text-highlight {
    font-family: "Raleway", serif;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5;
    color: #353a42;
    color: #0d98e6;
    font-weight: 500; }
  .footer-container-desk .home-paragraph {
    font-family: "Raleway", serif;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5;
    color: #353a42;
    font-size: 1.125rem;
    text-align: left;
    margin-block: 0 1.25rem; }
  .footer-container-desk form {
    width: 100%; }
    .footer-container-desk form label {
      font-family: "Raleway", serif;
      font-style: normal;
      font-weight: 300;
      line-height: 1.5;
      color: #353a42;
      font-weight: 400;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      font-size: 1rem;
      margin-top: 1rem;
      font-weight: 400; }
      .footer-container-desk form label:first-child {
        margin: 0; }
    .footer-container-desk form input {
      height: 38px;
      font-family: "Raleway", serif;
      font-style: normal;
      font-weight: 300;
      line-height: 1.5;
      color: #353a42;
      border: 1px solid white;
      border-radius: 4px;
      background-color: #fff;
      margin-top: 0.625rem;
      padding-inline: 14px;
      color: #8a8a8a;
      font-size: 0.875rem;
      width: 100%;
      -webkit-box-sizing: border-box;
      box-sizing: border-box; }
      .footer-container-desk form input:focus {
        background-color: transparent;
        border: 1px solid #0d98e6;
        outline: none; }
    .footer-container-desk form .enviar {
      all: unset;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
    .footer-container-desk form textarea {
      font-family: "Raleway", serif;
      font-style: normal;
      font-weight: 300;
      line-height: 1.5;
      color: #353a42;
      height: 119px;
      max-width: 100%;
      min-width: 100%;
      border: 1px solid white;
      border-radius: 4px;
      background-color: #fff;
      margin-top: 0.625rem;
      padding-inline: 14px;
      color: #8a8a8a;
      font-size: 0.875rem;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      padding-top: 8px; }
      .footer-container-desk form textarea:focus {
        background-color: transparent;
        border: 1px solid #0d98e6;
        outline: none; }
    .footer-container-desk form .submit-botao {
      all: unset;
      font-family: "Raleway", serif;
      font-style: normal;
      font-weight: 300;
      line-height: 1.5;
      color: #353a42;
      background-color: #0d98e6;
      color: #fff;
      border: none;
      border-radius: 5px;
      margin-top: 1.25rem;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      text-align: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      font-size: 1rem;
      font-weight: 500;
      height: 42px; }
      .footer-container-desk form .submit-botao-disabled {
        background-color: WHITE;
        color: #0d98e6;
        border: 1px #cfcfcf solid; }
  .footer-container-desk .footer-container-columns {
    width: 100%;
    margin-inline: 250px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }
    @media (min-width: 1600px) {
      .footer-container-desk .footer-container-columns {
        margin-inline: 350px; } }
  .footer-container-desk .footer-container-links {
    padding: 40px 0;
    background-color: #053766;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: distribute;
    justify-content: space-around; }
    .footer-container-desk .footer-container-links > ul {
      gap: 3rem; }
    .footer-container-desk .footer-container-links ul {
      list-style: none;
      padding: 0;
      font-family: "Raleway", serif;
      font-style: normal;
      font-weight: 300;
      line-height: 1.5;
      color: #353a42;
      font-size: 1rem;
      font-weight: 500;
      color: #8a8a8a;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row; }
    .footer-container-desk .footer-container-links .form-column {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      font-size: 1.5rem; }
      .footer-container-desk .footer-container-links .form-column .form-colum-title {
        margin: 0;
        font-weight: 300;
        font-size: 1.25rem; }
      .footer-container-desk .footer-container-links .form-column .link-scroll {
        font-family: "Raleway", serif;
        font-style: normal;
        font-weight: 300;
        line-height: 1.5;
        color: #353a42;
        font-size: 1rem;
        font-weight: 300;
        color: #fff;
        text-decoration: none;
        margin: 0;
        cursor: pointer; }
        .footer-container-desk .footer-container-links .form-column .link-scroll:visited {
          color: #fff; }
        .footer-container-desk .footer-container-links .form-column .link-scroll:hover {
          text-decoration: underline; }
    .footer-container-desk .footer-container-links .conectar {
      white-space: nowrap; }
    .footer-container-desk .footer-container-links .contato {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between; }
    .footer-container-desk .footer-container-links .social-media {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      gap: 20px; }
    .footer-container-desk .footer-container-links li {
      line-height: 1;
      list-style: none; }
      .footer-container-desk .footer-container-links li > ul {
        gap: 0.375rem; }
      .footer-container-desk .footer-container-links li a {
        font-family: "Raleway", serif;
        font-style: normal;
        font-weight: 300;
        line-height: 1.5;
        color: #353a42;
        font-size: 1rem;
        font-weight: 300;
        color: #fff;
        text-decoration: none; }
        .footer-container-desk .footer-container-links li a:visited {
          color: #fff; }
        .footer-container-desk .footer-container-links li a:hover {
          text-decoration: underline; }
  .footer-container-desk .footer-final {
    background: #353a42; }
  .footer-container-desk .footer-final-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-inline: 250px; }
    @media (min-width: 1600px) {
      .footer-container-desk .footer-final-container {
        margin-inline: 350px; } }
    .footer-container-desk .footer-final-container img {
      max-width: 110px; }
  .footer-container-desk .footer-final-text {
    color: #fff;
    text-align: end;
    font-size: 12px; }
  .footer-container-desk .first {
    margin-bottom: 0; }
